var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('v-container',[_c('v-row',[_vm._v(" loading ")])],1):(_vm.props && _vm.props.expo)?_c('v-container',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[(_vm.props && _vm.props.logo)?_c('v-img',{attrs:{"src":_vm.props.logo,"height":"150px","contain":""}}):_vm._e(),_c('h2',[_vm._v("The "+_vm._s(_vm.pointSystem.name)+" Point System")]),(_vm.props && _vm.props.header)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.props.header)}}):_vm._e()],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"headline"},[_vm._v(" Division Base Points x Number of Teams Entered = Total Points Earned ")]),_c('div',[_vm._v("1st Place = 100% of Points")]),_c('div',[_vm._v("2nd Place = 90% of 1st Place")]),_c('div',[_vm._v("3rd Place = 90 % of 2nd Place")]),_c('div',[_vm._v("And so on… with each place receiving 90% of the place before it.")])])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6","offset-md":"3"}},[_c('v-card',{staticClass:"text-center elevation-1",attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"dense":"","color":"color1 color1Text--text"}},[_c('v-toolbar-title',[_vm._v("Base Point Scale")])],1),_c('v-card-text',{staticClass:"px-0 text-center"},[_c('div',{staticClass:"title"},[_vm._v("Division Base Points")]),_c('div',{staticClass:"subtitle"},[_vm._v("Each division has a base number of points that never changes.")]),_c('v-data-table',{attrs:{"headers":[
              {text: 'Adults', value: 'adult', align: 'center'},
              {text: 'Juniors', value: 'juniors', align: 'center'},
              {text: 'Division Points', value: 'points', align: 'center'}
            ],"items":_vm.expoPoints,"options":_vm.pager,"hide-default-footer":"","dense":"","mobile-breakpoint":0},on:{"update:options":function($event){_vm.pager=$event}}})],1)],1)],1)],1),(_vm.props && _vm.props.footer)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"},domProps:{"innerHTML":_vm._s(_vm.props.footer)}})],1):_vm._e()],1):_c('v-container',{class:{'pa-0': !_vm.isTab},attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[(_vm.props && _vm.props.html)?_c('v-card',{attrs:{"flat":""},domProps:{"innerHTML":_vm._s(_vm.props.html)}}):_c('v-card',{attrs:{"flat":""}},[_c('h2',[_vm._v("The "+_vm._s(_vm.pointSystem.name)+" Point System")]),_c('p',[_vm._v(" Points are awarded for juniors tournaments. The amount of points awarded at each event will be determined by combining our \"Base Point Scale\" with the \"Team Participation Multiplier\" detailed below. ")]),_c('p',[_vm._v(" Points are cumulative and rankings are calculated by the sum of all points earned within the previous 365 days. ")])])],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-card',{staticClass:"text-center elevation-1",attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"dense":"","color":"color1 color1Text--text"}},[_c('v-toolbar-title',[_vm._v("Base Point Scale")])],1),_c('v-card-text',[(!_vm.multiAge)?_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.tables),function(table){return _c('v-col',{key:table.title,attrs:{"cols":"12","sm":"6","md":"3","xl":"3"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","color":"color2 color2Text--text"}},[_c('v-toolbar-title',[_vm._v(_vm._s(table.title))])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":table.rows,"options":_vm.pager,"hide-default-footer":"","dense":"","mobile-breakpoint":0,"sort-by":['fOrder']},on:{"update:options":function($event){_vm.pager=$event}},scopedSlots:_vm._u([{key:"item.finish",fn:function(ref){
            var item = ref.item;
return [(item.finish < 100000)?_c('span',[_vm._v(_vm._s(_vm._f("ordinal")(item.finish)))]):_c('span',[_vm._v("All others")])]}}],null,true)})],1)],1)],1)}),1):[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"title",attrs:{"cols":"12"}},[_vm._v(" Adult Events ")])],1),_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.tables.filter(function (f) { return f.ageType === 2; })),function(table){return _c('v-col',{key:table.title},[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","color":"color2 color2Text--text"}},[_c('v-toolbar-title',[_vm._v(_vm._s(table.title))])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":table.rows,"options":_vm.pager,"hide-default-footer":"","dense":"","mobile-breakpoint":0,"sort-by":['fOrder']},on:{"update:options":function($event){_vm.pager=$event}},scopedSlots:_vm._u([{key:"item.finish",fn:function(ref){
            var item = ref.item;
return [(item.finish < 100000)?_c('span',[_vm._v(_vm._s(_vm._f("ordinal")(item.finish)))]):_c('span',[_vm._v("All others")])]}}],null,true)})],1)],1)],1)}),1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"title",attrs:{"cols":"12"}},[_vm._v(" Junior Events ")])],1),_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.tables.filter(function (f) { return f.ageType === 1; })),function(table){return _c('v-col',{key:table.title,attrs:{"cols":"12","sm":"6","md":"3","xl":"3"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","color":"color2 color2Text--text"}},[_c('v-toolbar-title',[_vm._v(_vm._s(table.title))])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":table.rows,"options":_vm.pager,"hide-default-footer":"","dense":"","mobile-breakpoint":0,"sort-by":['fOrder']},on:{"update:options":function($event){_vm.pager=$event}},scopedSlots:_vm._u([{key:"item.finish",fn:function(ref){
            var item = ref.item;
return [(item.finish < 100000)?_c('span',[_vm._v(_vm._s(_vm._f("ordinal")(item.finish)))]):_c('span',[_vm._v("All others")])]}}],null,true)})],1)],1)],1)}),1)]],2)],1)],1)],1),(_vm.multipliers.length)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-card',{staticClass:"text-center"},[_c('v-toolbar',{attrs:{"dense":"","color":"color1 color1Text--text"}},[_c('v-toolbar-title',[_vm._v("Team Participation Multiplier")])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.mHeaders,"items":_vm.multipliers,"hide-default-footer":"","loading":_vm.loading,"options":{itemsPerPage: -1},"dense":""}})],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container v-if="loading">
    <v-row>
      loading
    </v-row>
  </v-container>
  <v-container v-else-if="props && props.expo">
    <v-row dense>
      <v-col>
          <v-img
            v-if="props && props.logo"
            :src="props.logo"
            height="150px"
            contain=""
          ></v-img>
          <h2>The {{pointSystem.name}} Point System</h2>
          <div v-if="props && props.header" v-html="props.header"></div>
      </v-col>
      <v-col cols="12" class="text-center">
        <div class="headline">
          Division Base Points x Number of Teams Entered = Total Points Earned
        </div>
        <div>1st Place = 100% of Points</div>
        <div>2nd Place = 90% of 1st Place</div>
        <div>3rd Place = 90 % of 2nd Place</div>
        <div>And so on… with each place receiving 90% of the place before it.</div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" md="6" offset-md="3">
        <v-card flat class="text-center elevation-1">
          <v-toolbar dense color="color1 color1Text--text">
            <v-toolbar-title>Base Point Scale</v-toolbar-title>
          </v-toolbar>
          <v-card-text class="px-0 text-center">
            <div class="title">Division Base Points</div>
            <div class="subtitle">Each division has a base number of points that never changes.</div>
            <v-data-table
              :headers="[
                {text: 'Adults', value: 'adult', align: 'center'},
                {text: 'Juniors', value: 'juniors', align: 'center'},
                {text: 'Division Points', value: 'points', align: 'center'}
              ]"
              :items="expoPoints"
              :options.sync="pager"
              hide-default-footer
              dense
              :mobile-breakpoint="0"
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="props && props.footer">
      <v-col cols="12" v-html="props.footer"></v-col>
    </v-row>
  </v-container>
  <v-container v-else fluid :class="{'pa-0': !isTab}">
    <v-row dense>
      <v-col cols="12">
        <v-card flat v-if="props && props.html" v-html="props.html">
        </v-card>
        <v-card flat v-else>
          <h2>The {{pointSystem.name}} Point System</h2>
          <p>
          Points are awarded for juniors tournaments.  The amount of points awarded at each event will be determined by combining our "Base Point Scale" with  the "Team Participation Multiplier" detailed below.
          </p>
          <p>
          Points are cumulative and rankings are calculated by the sum of all points earned within the previous 365 days.
          </p>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-card flat class="text-center elevation-1">
          <v-toolbar dense color="color1 color1Text--text">
            <v-toolbar-title>Base Point Scale</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row dense v-if="!multiAge">
              <v-col cols="12" sm="6" md="3" xl="3" v-for="table in tables" :key="table.title">
                <v-card>
                  <v-toolbar dense color="color2 color2Text--text">
                    <v-toolbar-title>{{table.title}}</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-data-table
                      :headers="headers"
                      :items="table.rows"
                      :options.sync="pager"
                      hide-default-footer
                      dense
                      :mobile-breakpoint="0"
                      :sort-by="['fOrder']"
                    >

                      <template v-slot:[`item.finish`]="{item}">
                        <span v-if="item.finish < 100000">{{item.finish | ordinal}}</span>
                        <span v-else>All others</span>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <template v-else>
              <v-row dense>
                <v-col cols="12" class="title">
                  Adult Events
                </v-col>
              </v-row>
              <v-row dense>
                <v-col v-for="table in tables.filter(f => f.ageType === 2)" :key="table.title">
                <v-card>
                  <v-toolbar dense color="color2 color2Text--text">
                    <v-toolbar-title>{{table.title}}</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-data-table
                      :headers="headers"
                      :items="table.rows"
                      :options.sync="pager"
                      hide-default-footer
                      dense
                      :mobile-breakpoint="0"
                      :sort-by="['fOrder']"
                    >

                      <template v-slot:[`item.finish`]="{item}">
                        <span v-if="item.finish < 100000">{{item.finish | ordinal}}</span>
                        <span v-else>All others</span>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>

              </v-row>
              <v-row dense>
                <v-col cols="12" class="title">
                  Junior Events
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6" md="3" xl="3" v-for="table in tables.filter(f => f.ageType === 1)" :key="table.title">
                <v-card>
                  <v-toolbar dense color="color2 color2Text--text">
                    <v-toolbar-title>{{table.title}}</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-data-table
                      :headers="headers"
                      :items="table.rows"
                      :options.sync="pager"
                      hide-default-footer
                      dense
                      :mobile-breakpoint="0"
                      :sort-by="['fOrder']"
                    >

                      <template v-slot:[`item.finish`]="{item}">
                        <span v-if="item.finish < 100000">{{item.finish | ordinal}}</span>
                        <span v-else>All others</span>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>

              </v-row>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense v-if="multipliers.length">
      <v-col>
        <v-card class="text-center">
          <v-toolbar dense color="color1 color1Text--text">
            <v-toolbar-title>Team Participation Multiplier</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-data-table
              :headers="mHeaders"
              :items="multipliers"
              hide-default-footer
              :loading="loading"
              :options="{itemsPerPage: -1}"
              dense
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import uniq from 'lodash.uniq'
import { firstBy } from 'thenby'
// import each from 'lodash.foreach'

export default {
  props: ['isTab', 'pointSystem'],
  data () {
    return {
      loading1: false,
      loading2: false,
      pointScale: null,
      multipliers: [],
      headers: [
        { text: 'Finish', align: 'left', sortable: false, value: 'finish' },
        { text: 'Points', align: 'left', sortable: false, value: 'points' }
      ],
      pager: { sortBy: ['finish'], itemsPerPage: -1 },
      mHeaders: [
        { text: 'Teams', align: 'left', sortable: false, value: 'description' },
        { text: 'Point Multiplier', align: 'left', sortable: false, value: 'multiplier' }
      ]
    }
  },
  computed: {
    tables () {
      if (!this.pointScale) return []
      const tables = uniq(this.pointScale.map(p => p.division)).sort().reverse()
      return tables.map((table) => {
        return {
          title: table,
          rows: this.pointScale.filter(p => p.division === table),
          get sortVal () {
            return Math.max(...this.rows.map(r => r.points))
          },
          get ageType () {
            return Math.max(...this.rows.map(r => r.ageType))
          }
        }
      }).sort(firstBy('ageType', -1).thenBy('sortVal', -1))
    },
    multiAge () {
      const x = new Set(this.tables.map(m => m.ageType))
      return x.size > 1
    },
    loading () {
      return this.loading1 || this.loading2
    },
    props () {
      return this.pointSystem.jsonProps && JSON.parse(this.pointSystem.jsonProps)
    },
    expoPoints () {
      if (this.pointScale) {
        var points = uniq(this.pointScale.map(p => p.points))
        return points.sort(firstBy(Number, -1)).map(m => {
          const a = this.pointScale.find(f => f.points === m && f.ageType === 2)
          const j = this.pointScale.find(f => f.points === m && f.ageType === 1)
          return {
            points: m,
            adult: a ? a.division : '-',
            juniors: j ? j.division : '-'
          }
        })
      }
      return null
    }
  },
  methods: {
    getScale () {
      this.loading1 = true
      this.$VBL.getPointScale(`${this.pointSystem.id}`)
        .then((response) => {
          this.pointScale = response.data
          this.loading1 = false
        })
        .catch((error) => {
          console.log(error)
          this.loading1 = false
        })
    },
    getMultipliers () {
      this.loading2 = true
      this.$VBL.getMultipliers(`${this.pointSystem.id}`)
        .then((response) => {
          this.multipliers = response.data
          this.loading2 = false
        })
        .catch((error) => {
          console.log(error)
          this.loading2 = false
        })
    }
  },
  created () {
    this.getScale()
    this.getMultipliers()
  }
}
</script>

<style>

</style>

<template>
  <v-container fluid :class="{'pa-0': !isTab}">
    <v-row dense>
      <v-col>
        <v-card flat v-if="props && props.html" v-html="props.html">
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-card class="text-center" :flat="!isTab">
          <v-toolbar dense color="color1 color1Text--text" v-if="isTab">
            <v-toolbar-title>Base Point Scale</v-toolbar-title>
          </v-toolbar>
          <v-card-text :class="{'pa-0': !isTab}">
            <v-row dense>
              <v-col cols="12" sm="6" v-for="table in tables" :key="table.title">
                <v-card>
                  <v-toolbar dense color="color2 color2Text--text">
                    <v-toolbar-title>{{table.title}}</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text class="pa-1">
                    <v-data-table
                      :headers="table.headers"
                      :items="table.rows"
                      :options.sync="pager"
                      :loading="loading1"
                      hide-default-footer
                      dense
                      :mobile-breakpoint="0"
                    >
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="text-center">
          <v-toolbar dense color="color1 color1Text--text">
            <v-toolbar-title>Team Participation Multiplier</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-data-table
              :headers="mHeaders"
              :items="multipliers"
              hide-default-footer
              :loading="loading2"
              dense
              :mobile-breakpoint="0"
            >
              <v-progress-linear slot="progress" color="color3" indeterminate></v-progress-linear>
              <template slot="items" slot-scope="props">
                <tr>
                  <td class="text-left">{{props.item.description}}</td>
                  <td class="text-left">{{props.item.multiplier}}</td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import { ordinal } from '@/Filters'
export default {
  props: ['isTab', 'pointSystem'],
  data () {
    return {
      loading1: false,
      loading2: false,
      pointScale: null,
      multipliers: [],
      tableData: [],
      pager: { sortBy: ['points'], itemsPerPage: -1 },
      mHeaders: [
        { text: 'Teams', align: 'left', sortable: false, value: 'description' },
        { text: 'Point Multiplier', align: 'left', sortable: false, value: 'multiplier' }
      ]
    }
  },
  computed: {
    props () {
      return this.pointSystem.jsonProps && JSON.parse(this.pointSystem.jsonProps)
    },
    tables () {
      return this.tableData.map(t => {
        const headers = t.headers
        headers.push('Team Points')
        return {
          title: t.title,
          headers: headers.map((h, i) => {
            return {
              text: h,
              align: 'center',
              sortable: false,
              value: `${i}`
            }
          }),
          rows: t.rows.map(r => {
            const a = {}
            const l = r.length - 1
            r.forEach((m, i) => {
              a[`${i}`] = i === l || m < 0 ? `${m}` : m ? `${ordinal(m)}` : ''
            })
            return a
          })
        }
      })
    },
    loading () {
      return this.loading1 || this.loading2
    }
  },
  methods: {
    getScale () {
      this.loading1 = true
      this.$VBL.getPointScale(this.pointSystem.id, true)
        .then((response) => {
          this.tableData = response.data
          this.loading1 = false
        })
        .catch((error) => {
          console.log(error)
          this.loading1 = false
        })
    },
    getMultipliers () {
      this.loading2 = true
      this.$VBL.getMultipliers(this.pointSystem.id)
        .then((response) => {
          this.multipliers = response.data
          this.loading2 = false
        })
        .catch((error) => {
          console.log(error)
          this.loading2 = false
        })
    }
  },
  mounted () {
    console.log('here')
    this.getScale()
    this.getMultipliers()
  }
}
</script>

<style>

</style>

<template>
  <v-container fluid v-if="!isTab">
    <v-row dense>
      <v-col cols="12">
        <v-card>
          <v-toolbar color="color1 color1Text--text" class="mx-auto">
            <v-toolbar-title>The {{pointSystem.name}} Point System</v-toolbar-title>
          </v-toolbar>
          <v-card-text :class="{'pa-2': $vuetify.breakpoint.xsOnly}">
            <demo-content v-if="jProps && jProps.grouped" :isTab="isTab" :pointSystem="pointSystem"></demo-content>
            <my-content v-else :isTab="isTab" :pointSystem="pointSystem"></my-content>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <demo-content v-else-if="jProps && jProps.grouped" :isTab="isTab" :pointSystem="pointSystem"></demo-content>
  <my-content v-else :isTab="isTab" :pointSystem="pointSystem"></my-content>
</template>

<script>
import Content from '@/components/Points/PointSystemContent'
import DemoContent from '@/components/Points/PointsContent'
import { mapGetters } from 'vuex'

export default {
  props: ['isTab', 'pointSystemId'],
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters(['theme']),
    pointSystem () {
      return this.theme.pointSystems.find(f => f.id === +this.pointSystemId)
    },
    jProps () {
      return this.pointSystem && this.pointSystem.jsonProps && JSON.parse(this.pointSystem.jsonProps)
    }
  },
  methods: {
  },
  components: {
    'my-content': Content,
    DemoContent
  }
}
</script>

<style>

</style>
